import './App.css';
import React, { useState } from 'react';
import axios from 'axios';

// Täydennetään aineistosta löytyvät lyhenteet koko sanoiksi
const translation = {
  srk: 'seurakunta',
  frs: 'församling',
  // Add more translations as needed
};

let lang;

console.log(window.location.pathname);

if (window.location.pathname === "/svenska") {
  lang = "se";
}
else if (window.location.pathname === "/english") {
  lang = "en";
}
else {
  lang = "fi";
}

function translateSrk(inputString, translation) {
  // Create a regex pattern that matches any abbreviation from the translation object
  const regexPattern = new RegExp(Object.keys(translation).join('|'), 'g');

  // Use the replace method with a callback function to perform the replacements
  const translatedString = inputString.replace(regexPattern, match => {
    return translation[match] || match; // Replace with translation if found, else keep the original
  });

  return translatedString;
}


function App() {

  const [address, setAddress] = useState('');
  const [results, setResults] = useState(null);

  const handleAddressChange = (e) => {
    setAddress(e.target.value);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    console.log(address);
    try {
      const response = await axios.get('/api/geocode', { params: { address }, 
        headers: {
          'Referer': 'https://mihinsrkkuulun.evlutkirkko.fi/',
          'User-Agent': 'Mihin seurakuntaan kuulun/1.0 (https://evl.fi/seurakunnat/)'
        }
    });
      console.log(response)
      setResults(response.data.rows);
    } catch (error) {
      setResults([]);

    }
  };
  return (
    <div className="App">

      <form onSubmit={handleFormSubmit}>
        <label>
          <p>
            {lang === "fi" && <>Anna katu, kadun numero ja kunta (esimerkiksi "Mannerheimintie 15 Helsinki"). Älä kirjoita esimerkiksi rapun numeroa.</>}
            {lang === "se" && <>Skriv din gatu, gatunummer och ort (till exempel Mannerheimvägen 15 Helsingfors). Skriv till inte exempel trappnumret.</>}
            {lang === "en" && <>Enter your street, street number and town (for example Mannerheimintie 15 Helsinki)</>}
          </p>
          <input
            type="text"
            value={address}
            onChange={handleAddressChange}
          />
        </label>
        <button type="submit">
          {lang === "fi" && <>Hae</>}
          {lang === "se" && <>Sök</>}
          {lang === "en" && <>Search</>}

        </button>
      </form>
      <div>
        {results === null ?
          <p>

          </p>
          :
          results.length === 0 ?
            <p>
              {lang === "fi" && <>Osoitteella ei löytynyt seurakuntaa. Tarkista osoitteesi kirjoitusasu.</>}
              {lang === "se" && <>Det finns ingen församling på den angivna adressen. Kolla rättstavningen.</>}
              {lang === "en" && <>Could not find a parish with this address. Please make sure the address is correclty written.</>}



            </p>

            :
            <ul>
              {lang === "fi" && <>Seurakuntasi on</>}
              {lang === "se" && <>Din församling är</>}
              {lang === "en" && <>Your parish is</>}

              {results.map((result, index) => (



                <><b key={index}>
                  &nbsp;{translateSrk(result.srk, translation)}


                </b><span>
                    {index < results.length - 1 ? <span>
                      {lang === "fi" && <> tai</>}
                      {lang === "se" && <> eller</>}
                      {lang === "en" && <> or</>}

                    </span> : null}</span></>
              ))}
            </ul>

        }
      </div>
      <div style={{ position: "absolute", bottom: 0, width: "100%" }}>

        <p align="center">
          <small>
            {lang === "fi" && <>Datalähteet: </>}
            {lang === "se" && <>Datakälla: </>}
            {lang === "en" && <>Data sources: </>}

            <a href="https://www.openstreetmap.org/" target="_blank">OpenStreetMap</a>, Kirkkohallitus (12.2.2025). 
          </small>
        </p>
      </div>


    </div>
  );
}

export default App;
